import { addedNewNotification } from '../../../../../(deprecated)/redux/notifications/actions'
import { MetaAdapter } from '../../../../../(deprecated)/api/MetaAdapter'
import { BuilderChecklistsApi, getChecklistsResponse } from '../types'
import moment from 'moment'
import QueryString from 'query-string'

type QueryArgsType = {
  page: number
  perPage: number
  searchText: string
}

export const getChecklists = (builder: BuilderChecklistsApi) => (
  builder.query<getChecklistsResponse, QueryArgsType>({
    providesTags: ['checklists-api'],
    query: ({ page, perPage, searchText }) => {
      const queryString = QueryString.stringify({
        page: page - 1,
        size: perPage,
        ...(searchText && { roomName: searchText })
      }, { skipNull: true })

      return `finishing?${queryString}`
    },
    //@ts-ignore
    transformResponse: (response: getChecklistsResponse) => {
      const listOptions = response.content.map(item => ({
        ...item,
        dateUpdate: moment(item.dateUpdate).format('DD.MM.YYYY')
      }))

      const meta = new MetaAdapter({
        page: response.number + 1,
        perPage: response.size,
        totalPages: response.totalPages,
        totalResults: response.totalElements
      }).getFields()

      return {
        meta,
        listOptions
      }
    },

    async onQueryStarted(arg, { dispatch, queryFulfilled }) {
      try {
        await queryFulfilled
      } catch (e) {
        dispatch(addedNewNotification({ message: 'Не удалось загрузить чеклисты' }))
      }
    }
  })
)