import { BuilderChecklistsApi } from '../types'
import { ChecklistEntity } from '../../../../entities/Checklists'
import { format } from 'date-fns'
import { addedNewNotification } from '../../../../../(deprecated)/redux/notifications/actions'

export const getCheckListById = (builder: BuilderChecklistsApi) => (
  builder.query<ChecklistEntity, { id: number }>({
    query: ({ id }) => {
      return `finishing/${id}`
    },

    transformResponse: (response: ChecklistEntity) => ({
      ...response,
      dateUpdate: format(new Date(response.dateUpdate), 'dd.MM.yyyy')
    }),

    async onQueryStarted(arg, { dispatch, queryFulfilled }) {
      try {
        await queryFulfilled
      } catch (e) {
        dispatch(addedNewNotification({ message: 'Не удалось загрузить чеклист' }))
      }
    }
  })
)